/* Tema Renkleri için CSS Değişkenleri */      
:root {
  --background-color: #121212;
  --foreground-color: #1e1e1e;
  --text-color: #ffffff;
  --accent-color: #26a49b; /* Turkuaz */
  --accent-color-hover: #244c72;
  --accent-color-active: #24498b;
  --border-color: #444;
  --placeholder-color: #888;
  --disconnect-color: #ff0000; /* Kırmızı */
  --disconnect-color-hover: #cc0000; /* Koyu Kırmızı */
  --disconnect-color-active: #990000; /* Bordo */
}

/* Genel Stiller */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

html, body {
  height: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Popup Stilleri */
#popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.welcome-image {
  width: 250px;
  height: 250px;
  object-fit: contain; /* Değiştirildi: cover yerine contain */
  border-radius: 0; /* Kare görünüm için border-radius'u sıfırladık */
  margin-bottom: 20px;
}

#connect-wallet-btn {
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  background-color: var(--accent-color);
  color: var(--text-color);
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 20px; /* Görsel ile buton arasında boşluk */
}

#connect-wallet-btn:hover {
  background-color: var(--accent-color-hover);
}

#connect-wallet-btn:active {
  background-color: var(--accent-color-active);
}

/* Üst Navigasyon Stilleri */
#nav-menu {
  background-color: var(--foreground-color);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 100;
}

/* Navigasyon Sol Kısmı */
.nav-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-left button {
  background-color: transparent;
  border: none;
  color: var(--text-color);
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: color 0.3s, border-bottom 0.3s;
}

.nav-left button.active {
  border-bottom: 2px solid var(--accent-color);
}

.nav-left button:hover {
  color: var(--accent-color);
}

/* Navigasyon Orta Kısmı */
.nav-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 70px; /* Fotoğrafı sağa kaydırmak için eklendi */
}

.nav-logo {
  width: 40px;
  height: 40px;
  object-fit: contain; /* Değiştirildi: cover yerine contain */
  border-radius: 0; /* Kare görünüm için border-radius'u sıfırladık */
}

/* Navigasyon Sağ Kısmı */
.nav-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

#wallet-address {
  font-size: 14px;
  color: var(--text-color);
  white-space: nowrap;
}

#disconnect-btn {
  border: 1px solid var(--border-color);
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--disconnect-color-active); /* Bordo */
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}

#disconnect-btn:hover,
#disconnect-btn:active {
  background-color: var(--disconnect-color-active); /* Bordo */
  color: var(--text-color); /* Metin rengi beyaz kalır */
}

/* Ana İçerik */
#main-content {
  min-height: calc(100vh - 70px); /* Navigasyon yüksekliğine göre ayarlandı */
  padding: 20px;
}

/* Form Stilleri */
form {
  margin-bottom: 30px;
}

textarea, input[type="text"], input[type="number"] {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  background-color: var(--foreground-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 15px;
  resize: vertical;
}

textarea::placeholder,
input::placeholder {
  color: var(--placeholder-color);
}

button {
  padding: 10px 12px;
  font-size: 14px;
  cursor: pointer;
  background-color: var(--accent-color);
  color: var(--text-color);
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: var(--accent-color-hover);
}

button:active {
  background-color: var(--accent-color-active);
}

/* Görüşler Bölümü */
#opinions-section {
  width: 80%;
  margin: 20px auto;
}

#opinion-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

#opinion-text {
  resize: vertical;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
}

/* Görüş Metninin Taşmasını Önleme */
.opinion p {
  margin: 0;
  line-height: 1.5;
  overflow-wrap: break-word; /* Metin taşmasını önler */
  word-wrap: break-word; /* Eski tarayıcılar için */
  word-break: break-word; /* Gerekirse kelime kırma */
}

/* Görüş Ayracı */
.opinion {
  border-bottom: 1px solid var(--border-color);
  padding: 10px 0;
}

/* Cüzdan Adresi Stili */
.wallet-address {
  color: #555;
  font-size: 0.9em;
}

/* Yorum Tarihi Stili */
.opinion-date {
  color: gray;
  font-size: 0.9em;
  margin-top: 5px;
}

/* Sayfalama Stilleri */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  border: 1px solid #ddd;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  background-color: white;
  border-radius: 3px;
  color: black; /* Metin rengini siyah olarak ayarlandı */
}

.page-button:hover {
  background-color: #f1f1f1;
}

.page-button.active {
  background-color: var(--accent-color);
  color: white;
  border: 1px solid var(--accent-color);
}

/* Anket Resmi */
.poll-image {
  width: auto;
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
}

/* Sonuçlar Listesi */
.results-list {
  margin-top: 20px;
}

.result-item {
  margin-bottom: 15px;
}

.result-item span {
  display: block;
  margin-bottom: 5px;
}

.progress-bar-container {
  background-color: var(--border-color);
  border-radius: 5px;
  overflow: hidden;
  height: 20px;
}

.progress-bar {
  height: 100%;
  background-color: var(--accent-color);
  width: 0%;
  transition: width 0.5s;
}

/* Geri Sayım Zamanlayıcı */
.time-remaining {
  font-style: italic;
  margin-top: 10px;
  color: var(--placeholder-color);
}

/* Bölüm Başlıkları */
#vote-section h2 {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
  margin-top: 20px;
}

/* Anketler Bölümü */
#vote-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.poll-container {
  width: 48%;
  background-color: var(--foreground-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.polls-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.poll-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--border-color);
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  box-sizing: border-box;
}

.poll-summary:hover {
  background-color: var(--accent-color-hover);
}

.poll-summary img {
  width: 500px;
  height: 500px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Anket Başlıklarının Taşmasını Önleme */
.poll-summary h3 {
  text-align: center;
  margin: 0;
  width: 100%; /* Tam genişlik kullan */
  overflow-wrap: break-word; /* Metin taşmasını önler */
  word-wrap: break-word; /* Eski tarayıcılar için */
  word-break: break-word; /* Gerekirse kelime kırma */
}

/* Oylama Formu */
.vote-form {
  margin-top: 15px;
}

.vote-form label {
  display: block;
  margin-bottom: 5px;
}

.vote-form input[type="radio"] {
  margin-right: 10px;
}

.vote-form select {
  width: 100%;
  padding: 10px;
  background-color: var(--foreground-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 15px;
}

.vote-form button {
  padding: 10px 12px;
  font-size: 14px;
  cursor: pointer;
  background-color: var(--accent-color);
  color: var(--text-color);
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.vote-form button:hover {
  background-color: var(--accent-color-hover);
}

.vote-form button:active {
  background-color: var(--accent-color-active);
}

/* Modal Stilleri */
.modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(18, 18, 18, 0.95);
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: var(--foreground-color);
  padding: 40px;
  width: 90%;
  max-width: 900px;
  position: relative;
  border-radius: 5px;
  color: var(--text-color);
  /* Yeni Eklenen Özellikler */
  max-height: 90vh; /* Modal yüksekliği görünüm yüksekliğinin %90'ı */
  overflow-y: auto; /* İçerik fazla olursa dikey kaydırma çubuğu ekler */
}

/* Modal Kapatma Butonları */
.close, .close-detail {
  color: #aaa;
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}

.close:hover, .close-detail:hover {
  color: var(--text-color);
}

/* Modal İçindeki Anket Detayları */
#poll-detail-content img.poll-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

/* Modal Başlık ve Açıklama Stilleri */
#poll-detail-content h2,
#poll-detail-content p {
  overflow-wrap: break-word; /* Metin taşmasını önler */
  word-wrap: break-word; /* Eski tarayıcılar için */
  word-break: break-word; /* Gerekirse kelime kırma */
  margin-bottom: 15px; /* Başlık ve açıklama arasına boşluk ekler */
}

#poll-detail-content h2 {
  font-size: 1.5rem; /* Başlık font boyutu ayarlandı */
}

#poll-detail-content p {
  font-size: 1rem; /* Açıklama font boyutu ayarlandı */
}

/* Formlar için Ek Stiller */
#poll-form label,
.vote-form label {
  display: block;
  margin-bottom: 5px;
  color: var(--text-color);
}

#poll-form input[type="text"],
#poll-form textarea,
#poll-form input[type="number"],
.vote-form select {
  width: 100%;
  padding: 10px;
  background-color: var(--foreground-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 15px;
}

#poll-form input::placeholder,
#poll-form textarea::placeholder {
  color: var(--placeholder-color);
}

#options-container .option-input {
  width: 100%;
  padding: 10px;
  background-color: var(--foreground-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 10px;
}

#add-option-btn {
  padding: 10px 12px;
  font-size: 14px;
  cursor: pointer;
  background-color: var(--accent-color);
  color: var(--text-color);
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: background-color 0.3s;
}

#add-option-btn:hover {
  background-color: var(--accent-color-hover);
}

#add-option-btn:active {
  background-color: var(--accent-color-active);
}

/* Modal Başlığını Ortalamak */
#poll-form h2 {
  text-align: center;
}

/* Duyarlı Tasarım */
@media screen and (max-width: 800px) {
  .poll-summary {
    width: calc(50% - 40px);
  }
}

@media screen and (max-width: 600px) {
  #connect-wallet-btn {
    padding: 10px 20px;
    font-size: 16px;
  }

  #nav-menu button {
    padding: 10px 15px;
    font-size: 14px;
  }

  textarea, input[type="text"], input[type="number"] {
    font-size: 12px;
  }

  button {
    font-size: 12px;
    padding: 10px 20px;
  }

  .modal-content {
    padding: 20px;
    transform: scale(0.9);
    transform-origin: top left;
    max-width: 95%; /* Mobilde modal biraz daha geniş */
  }

  .poll-summary {
    width: 100%;
  }
}

/* Ek Stil Ayarlamaları */
.small-text {
  font-size: 0.85rem;
  color: var(--placeholder-color);
}

#poll-duration {
  margin-top: 20px;
}

/* Süre Notu Stili */
.duration-note {
  font-size: 0.9em;
  color: var(--placeholder-color);
  margin-left: 5px;
}
